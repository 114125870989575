import React from 'react'
import classNames from 'classnames'

import '../styles/service_main.scss'
import Layout from './layout'
import BrochureTile from './brochureTile'
import ArrowButton from './arrowButton'
import Seo from './seo'
import BlissLabel from './blissLabel'

const Service = ({ data, lang, location, pageContext }) => {
  const { allImageSharp, logo, sitePage } = data
  const { serviceData: info, even } = pageContext
  const Image = require(`../images/services/large/${info.image}.svg`)
  const postData = {
    title: `Bliss DDM | ${lang.menu.services} | ${info.name}`,
    path: sitePage.path,
  }
  return (
    <Layout
      lang={lang}
      location={location}
      noShowQuote
      contrast
      logo={logo.sizes}
      images={allImageSharp.nodes.map(node => node.sizes)}
      component={({ toggleQuote }) => (
        <>
          <div className={classNames('Service', { 'Service--even': even })}>
            <div className="Service-container">
              <div className="Service-info">
                <BlissLabel label={lang.menu.services} />
                <h1 className="Service-title" dangerouslySetInnerHTML={{ __html: info.htmlName }} />
                <div className="Service-description" dangerouslySetInnerHTML={{ __html: info.description }} />
                <div className="Service-description Service-description--extended" dangerouslySetInnerHTML={{ __html: info.extendedDescription }} />
                <ul className="Service-items">
                  {info.items.map(item => <li key={item} className="Service-item">{item}</li>)}
                </ul>
                <div className="Service-quote">
                  {lang.quotes} {' '}
                  <b
                    className="blueHighlight"
                    onClick={toggleQuote}
                    onKeyPress={toggleQuote}
                    tabIndex={0}
                    role="button"
                  >
                    {lang.here} {' '}
                  </b>
                  {lang.withUS}
                </div>
              </div>
              <div className="Service-imageContainer">
                <Image className="Service-image" />
              </div>
              <div
                className="Service-quote Service-quote--mobile"
                onClick={toggleQuote}
                onKeyPress={toggleQuote}
                tabIndex={0}
                role="button"
              >
                {lang.quotes} {' '}
                <b className="blueHighlight">
                  {lang.here} {' '}
                </b>
                {lang.withUS}
              </div>
              <ArrowButton modifier="service" label={lang.serviceBarText} to={`/${lang.lang}/services#list`} />
            </div>
            <ArrowButton label={lang.serviceBarText} to={`/${lang.lang}/services#list`} />
            <BrochureTile label={lang.downloadBrochure} lang={lang.lang} />
          </div>

          <Seo
            lang={lang}
            postData={postData}
            imageOverride={`/seo/services/${info.image}.png`}
            isService
            descriptionOverride={info.seoDescription || info.description}
          />
        </>
      )}
    />
  )
}

export default Service
