import React from 'react'
import { graphql } from 'gatsby'
import lang from '../data/en.json'
import ServiceComponent from '../components/service'
import withWindowSize from '../components/withWindowSize'

const Service = props => (
  <ServiceComponent {...props} lang={lang} />
)

export const query = graphql`
  query ServiceEnQuery($slug: String!) {
    logo: imageSharp(fluid: { originalName: { eq: "logo.png" }}) {
      sizes(quality: 100, maxWidth: 75, maxHeight: 50, cropFocus: CENTER){
        ...GatsbyImageSharpSizes_withWebp
      }
    }
    allImageSharp(
      filter: { fluid: { originalName: { glob: "{bg,background}*.png" } } }
    ) {
      nodes {
        sizes(quality: 100, maxWidth: 1800) {
          ...GatsbyImageSharpSizes_withWebp
          originalImg
          originalName
        }
      }
    }
    sitePage(context: { slug: { eq: $slug }, isEn: { eq: true } }) {
      id
      path
      context {
        isEn
        even
        slug
        serviceData {
          index
          name
          htmlName
          image
          description
          seoDescription
          items
          slug
          extendedDescription
        }
      }
    }
  }
`

export default withWindowSize(Service)
